import { clsx, type ClassValue } from "clsx";
import { format, Locale } from "date-fns";
import { twMerge } from "tailwind-merge";

import { BillingCycle } from "@libs/types/invoice";
import { Operator } from "@libs/types/products";
import { SlotGroup, SlotV2 } from "@libs/types/products/reservation";

import { PaymentMethod } from "./types/app";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function hasApplePay() {
  // @ts-ignore
  return typeof window !== "undefined" && window.ApplePaySession;
}

export function getHost() {
  return typeof window !== "undefined" ? window.location.host : "";
}

export function setTenant(value: string) {
  console.info("setTenant >>> ", value);
  if (value == "") {
    console.error("Tenant is empty");
  }
  typeof window !== "undefined" && sessionStorage.setItem("tenant", value);
}

export function getTenant() {
  return typeof window !== "undefined"
    ? sessionStorage.getItem("tenant") ?? ""
    : "";
}

export function getOrigin() {
  return typeof window !== "undefined" ? window.location.origin : "";
}

export function convertToEnglishNumbers(text: string) {
  const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
  let newValue = text
    .split("")
    .map(function(char) {
      const index = arabicNumerals.indexOf(char);
      return index !== -1 ? index : char;
    })
    .join("");

  return newValue;
}

export const REGEXP_ARABIC_AND_ENGLISH_DIGITS = "^[0-9٠١٢٣٤٥٦٧٨٩]+$";

export function roundToTwo(num: number) {
  return Math.round(num * 100) / 100;
}

export function generateGUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function extractGUID(url: string): string | null {
  const regex = /[\da-zA-Z]{8}-([\da-zA-Z]{4}-){3}[\da-zA-Z]{12}/;
  const match = url.match(regex);
  return match ? match[0] : null;
}

export const getTenantName = () => {
  return sessionStorage.getItem("tenant");
};

export const safeParse = (str: string | null) => {
  if (!str) return null;
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

export const safeParseDate = (str: string | null, errorMessage: string) => {
  if (!str) return errorMessage;
  try {
    return new Date(str);
  } catch (e) {
    return errorMessage;
  }
};

export const maxInput = (e: React.FormEvent<HTMLInputElement>, len: number) => {
  if (e.currentTarget.value.length > len) {
    e.currentTarget.value = e.currentTarget.value.slice(0, len);
  }
};

export function getBillingPeriodLocalizationKey(subscription: any) {
  if (!subscription) return;
  switch (subscription.billingCycle) {
    case BillingCycle.AtBeggining:
    case BillingCycle.AtEnd:
      switch (subscription.billingPeriod) {
        case 30:
          return "Monthly";
        case 60:
          return "TwoMonths";
        case 90:
          return "3Months";
        case 180:
          return "6Months";
        case 365:
          return "Yearly";
        default:
          return `EveryXDays ${subscription.billingPeriod}`;
      }
    case BillingCycle.EveryEndOfMonth:
      return "Monthly";
    case BillingCycle.EveryEndOfQuarter:
      return "Quarterly";
    case BillingCycle.EveryEndOfYear:
      return "Yearly";
    default:
      return "";
  }
}

export const parseAndFormatTime = (
  date: string,
  outputFormat: "12h" | "24h" = "24h",
  locale?: Locale,
): string => {
  if (!date) return "";
  const formatString = outputFormat === "12h" ? "hh:mm a" : "HH:mm";
  return format(date, formatString, { locale });
};

export const getDayTimeString = (date: Date, isNextDay: boolean) => {
  const hour = date.getHours();

  if (hour >= 0 && hour <= 4 && !isNextDay) {
    return {
      name: "dawn",
      index: 0,
    };
  } else if (hour >= 0 && hour <= 4 && isNextDay) {
    return {
      name: "midnight",
      index: 4,
    };
  } else if (hour > 4 && hour < 12) {
    return {
      name: "morning",
      index: 1,
    };
  } else if (hour >= 12 && hour <= 17) {
    return {
      name: "noon",
      index: 2,
    };
  } else {
    return {
      name: "evening",
      index: 3,
    };
  }
};

export const groupSlotsByDaytime = (
  slots: SlotV2[],
  selectedDate: Date | undefined,
): SlotGroup[] => {
  if (!slots || !selectedDate) return [];

  // cutoff is 4am
  const today = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate(),
    4,
  );
  const tomorrow = new Date(
    selectedDate.getFullYear(),
    selectedDate.getMonth(),
    selectedDate.getDate() + 1,
    4,
  );
  const groupedSlots: SlotGroup[] = [];
  slots.forEach((slot) => {
    var from = slot.from;
    if (from < today || from >= tomorrow) return;

    var isNextDay = new Date(selectedDate)?.getDate() !== from.getDate();
    const daytime = getDayTimeString(slot.from, isNextDay);
    const group = groupedSlots.find((g) => g.name === daytime.name);
    if (group) {
      group.slots.push(slot);
    } else {
      groupedSlots.push({
        name: daytime.name,
        slots: [slot],
        index: daytime.index,
      });
    }
  });

  groupedSlots.forEach((group) => {
    group.slots.sort((a, b) => a.from.getTime() - b.from.getTime());
  });

  // sort by index
  return groupedSlots.sort((a, b) => a.index - b.index);
};

export const durationString = (
  minutes: number,
  hourLabel: string,
  minuteLabel: string,
) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  let result = "";
  if (hours > 0) {
    result += `${hours} ${hourLabel} `;
  }
  if (remainingMinutes > 0) {
    result += `${remainingMinutes} ${minuteLabel}`;
  }
  return result.trim();
};

export function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string,
): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}

export function hasValue(value: any) {
  return Boolean(value) ? value : 0;
}

export const chunkArray = (array: any[], chunkSize: number) => {
  const results = [];
  while (array.length) {
    results.push(array.splice(0, chunkSize));
  }
  return results;
};

export const compareDate = (dateA: Date, dateB: Date, operator: Operator) => {
  const date1 = new Date(
    dateA.getFullYear(),
    dateA.getMonth(),
    dateA.getDate(),
  );
  const date2 = new Date(
    dateB.getFullYear(),
    dateB.getMonth(),
    dateB.getDate(),
  );

  if (operator === Operator.GreaterThan) {
    return date1.valueOf() > date2.valueOf();
  }
  if (operator === Operator.GreaterOrEqual) {
    return date1.valueOf() >= date2.valueOf();
  }

  if (operator === Operator.LessThan) {
    return date1.valueOf() < date2.valueOf();
  }
  if (operator === Operator.LessOrEqual) {
    return date1.valueOf() <= date2.valueOf();
  }
  if (operator === Operator.NotEqual) {
    return date1.valueOf() !== date2.valueOf();
  }

  return date1.valueOf() === date2.valueOf();
};

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
 */
export function storageAvailable(
  type: "localStorage" | "sessionStorage",
): boolean {
  let storage;
  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return !!(
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

export function addMinutesToDate(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * 60000);
}

export const getEnabledPaymentMethods = (settings: any) => {
  if (!settings) return [];

  const enabledPaymentMethods: PaymentMethod[] = [];
  if (settings["Platform.PaymentMethod.Mada"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.MADA);
  }
  if (settings["Platform.PaymentMethod.CreditCard"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.VISA);
  }
  if (settings["Platform.PaymentMethod.CashOnDelivery"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.CASH);
  }
  if (settings["Platform.PaymentMethod.ApplePay"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.APPLE_PAY);
  }
  if (settings["Platform.PaymentMethod.Tamara"] === "True") {
    enabledPaymentMethods.push(PaymentMethod.TAMARA);
  }
  return enabledPaymentMethods;
};

export const generateRandomString = () =>
  Math.random().toString(36).substring(2, 8);

export function formatBytes(
  bytes: number,
  opts: {
    decimals?: number;
    sizeType?: "accurate" | "normal";
  } = {},
) {
  const { decimals = 0, sizeType = "normal" } = opts;

  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const accurateSizes = ["Bytes", "KiB", "MiB", "GiB", "TiB"];
  if (bytes === 0) return "0 Byte";
  const i = Math.floor(Math.log(bytes) / Math.log(1024));
  return `${(bytes / Math.pow(1024, i)).toFixed(decimals)} ${sizeType === "accurate" ? accurateSizes[i] ?? "Bytes" : sizes[i] ?? "Bytes"
    }`;
}

// map frontend enum to backend enum
export function mappedPaymentMethod(paymentMethod: PaymentMethod) {
  switch (paymentMethod) {
    case PaymentMethod.MADA:
      return 2;
    case PaymentMethod.VISA:
      return 2;
    case PaymentMethod.CASH:
      return 1;
    case PaymentMethod.APPLE_PAY:
      return 2;
    case PaymentMethod.TAMARA:
      return 2;
    case PaymentMethod.BANK_TRANSFER:
      return 3;
    default:
      return 1;
  }
}

export function copyToClipboard(
  text: string,
  onSuccess: (result?: any, ...args: any[]) => any,
  onFailure: (error: any, ...args: any[]) => any,
) {
  if (!navigator.clipboard || !navigator.clipboard.writeText) return;

  navigator.clipboard
    .writeText(text)
    .then((result) => onSuccess(result))
    .catch((err) => onFailure(err));
}
