import Link from "next/link";
import { useRouter } from "next/navigation";

import { useI18n } from "@repo/libs/providers/locales/client";
import { useTenantRouter } from "@repo/libs/providers/tenant/client";
import { useTenantStore } from "@libs/stores/useTenantStore";

import { Button } from "@repo/ui/components/button";

export default function NotFoundError() {
  const t = useI18n();
  const router = useRouter();
  const tenant = useTenantStore((state) => state.tenant);
  const tenantRouter = useTenantRouter();
  return (
    <section className="bg-white">
      <div className="container mx-auto min-h-screen px-6 py-12 lg:flex lg:items-center lg:gap-12">
        <div className="wf-ull lg:w-1/2">
          <p className="text-sm font-medium text-gray-400 ">
            {t("errors.404.error")}
          </p>
          <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl">
            {t("errors.404.title")}
          </h1>
          <p className="mt-4 text-gray-500">{t("errors.404.description")}</p>

          <div className="mt-6 flex items-center gap-x-3">
            <Button
              onClick={() => router.back()}
              className="flex w-1/2 items-center justify-center gap-x-2 rounded-lg border bg-white px-5 py-2 text-sm text-gray-700 transition-colors duration-200 hover:bg-gray-100 sm:w-auto"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5 rtl:rotate-180"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
              </svg>
              <span>{t("errors.back")}</span>
            </Button>

            <Button asChild>
              <Link href={tenant ? tenantRouter("/") : "https://rekaz.io/home"}>
                {t("errors.home")}
              </Link>
            </Button>
          </div>
        </div>

        <div className="relative mt-12 w-full lg:mt-0 lg:w-1/2">
          {/** TODO: Get image **/}
          {/**<img className="w-full max-w-lg lg:mx-auto" src="/images/components/illustration.svg" alt="">**/}
        </div>
      </div>
    </section>
  );
}
