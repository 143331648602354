"use client";
import { useCurrentLocale } from "@libs/providers/locales/client";
import isURL from "validator/lib/isURL";
import { useTenantStore } from "@libs/stores/useTenantStore";

export function useTenantRouter() {
  const tenant = useTenantStore.getState().tenant;
  const locale = useCurrentLocale();

  return function router(path: string) {
    const tenantPath = isURL(tenant) ? "" : `/${tenant}`;
    const localeQuery = locale !== "ar" ? `?lang=${locale}` : "";

    return `${tenantPath}${path}${localeQuery}`;
  };
}
