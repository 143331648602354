export interface ApplicationConfiguration {
  currentTenant: Tenant;
  settings: Settings;
}

export interface Tenant {
  id: string;
  name: string;
  isAvailable: boolean;
}

export interface Settings {
  [key: string]: string;
}

export enum PaymentMethod {
  MADA = "mada",
  VISA = "visa",
  CASH = "cash",
  APPLE_PAY = "applepay",
  TAMARA = "tamara",
  BANK_TRANSFER = "banktransfer",
}

export type EnabledPaymentMethods = {};
