import { UUID } from "crypto";

import { CustomField } from "../customFields";
import { Merchandise } from "./merchandise";
import { Pricing } from "./pricing";
import { ReservationProduct } from "./reservation";
import { Subscription } from "./subscription";

export interface ProductBase {
  id: UUID;
  name: string;
  description: string;
  shortDescription: string;
  amount: number;
  images: string[];
  pricing: Pricing[];
  type: ProductType;
  typeString: ProductType;
  discountedAmount?: number;
  customFields: CustomField[];
  addOns: CustomField[];
}

type Items = {
  id: string;
  amount: number;
  fromQuantity: number;
  toQuantity: number;
  flatAmount: number;
  discountedAmount?: number;
};

export enum ProductType {
  RESERVATION = "reservation",
  MERCHANDISE = "merchandise",
  SUBSCRIPTION = "subscription",
}

export enum Operator {
  GreaterThan,
  LessThan,
  GreaterOrEqual,
  LessOrEqual,
  Equal,
  NotEqual,
}
export type Product = ReservationProduct | Merchandise | Subscription;
