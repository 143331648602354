'use client';

import NotFoundError from "@/components/Errors/NotFoundError";

// TODO: translate
export default function NotFound() {
  return (
    <NotFoundError />
  )
}
