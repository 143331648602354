export interface Invoice {
  id: string;
  creationTime: string;
  creatorId: string;
  lastModificationTime: string;
  lastModifierId: string;
  isDeleted: boolean;
  deleterId: string;
  deletionTime: string;
  customerId: string;
  customerName: string;
  customerMobile: string;
  customerAddress: string;
  customerEmail: string;
  customerCrNumber: string;
  customerVatNumber: string;
  branchId: string;
  branchName: string;
  price: number;
  paidAmount: number;
  totalPrice: number;
  remainingAmount: number;
  url: string;
  status: number;
  statusString: string;
  code: string;
  discount: number;
  vatAmount: number;
  priceWithoutVat: number;
  items: InvoiceItem[];
  businessInfo: BusinessInfo;
  crNumber: string;
  commercialName: string;
  vatNumber: string;
  address: string;
  logo: string;
  notes: string;
  taxEnable: boolean;
  transferInfo: TransferInfo;
  invoiceNumber: number;
}

export interface InvoiceItem {
  quantity: number;
  price: number;
  totalPrice: number;
  totalNet: number;
  discount: number;
  name: string;
  description: string;
  type: number;
  reference: string;
  invoiceId: string;
  vatPercentage: number;
  vatAmount: number;
}

export interface BusinessInfo {
  crNumber: string;
  commercialName: string;
  vatNumber: string;
  address: string;
  logo: string;
}

export interface TransferInfo {
  bankName: string;
  iban: string;
}

export enum InvoiceItemType {
  Manual = 1,
  Subscription = 2,
  Order = 3,
}

export enum BillingCycle {
  AtBeggining = 1,
  AtEnd = 2,
  EveryEndOfMonth = 3,
  EveryEndOfQuarter = 4,
  EveryEndOfYear = 5,
}
