import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface State {
  tenant: string;
  setTenant: (tenant: string) => void;
}

export const useTenantStore = create<State>()(
  devtools(
    persist(
      (set) => ({
        tenant: "null",
        setTenant: (tenant) => set({ tenant }),
      }),
      {
        onRehydrateStorage: (state) => {
          console.log("hydration starts", state);

          return (state, error) => {
            if (error) {
              console.log("an error happened during hydration", error);
            } else {
              console.log("hydration finished", state);
            }
          };
        },
        // NOTE: will test this later
        // skipHydration: true,
        name: "tenant",
      },
    ),
  ),
);
