import * as React from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";

import { cn } from "@repo/libs/utils";

const baseStyles =
  "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50";

const themeBaseStyles = {
  customer: "ring-offset-theme-background focus-visible:ring-theme-primary",
  rekaz: "ring-offset-white focus-visible:ring-neutral-950",
};

const getThemeVariants = (theme: "customer" | "rekaz") =>
  cva(
    cn(
      baseStyles,
      theme === "customer" ? themeBaseStyles.customer : themeBaseStyles.rekaz,
    ),
    {
      variants: {
        variant: {
          default:
            theme === "customer"
              ? "bg-theme-primary text-theme-background hover:bg-theme-primary-600"
              : "bg-neutral-900 text-neutral-50 hover:bg-neutral-900/90",
          destructive:
            theme === "customer"
              ? "bg-red-500 text-neutral-50 hover:bg-red-500/90"
              : "bg-red-500 text-neutral-50 hover:bg-red-500/90",
          outline:
            theme === "customer"
              ? "border border-theme-primary bg-theme-background hover:bg-theme-primary hover:text-theme-background"
              : "border border-neutral-200 bg-white hover:bg-neutral-100 hover:text-neutral-900",
          // not needed for now
          secondary:
            theme === "customer"
              ? ""
              : "bg-neutral-100 text-neutral-900 hover:bg-neutral-100/80",
          ghost:
            theme === "customer"
              ? "hover:bg-theme-primary hover:text-theme-background"
              : "hover:bg-neutral-100 hover:text-neutral-900",
          link:
            theme === "customer"
              ? "text-theme-primary underline-offset-4 hover:underline"
              : "text-neutral-900 underline-offset-4 hover:underline",
        },
        size: {
          default: "h-10 px-4 py-2",
          sm: "h-9 rounded-md px-3",
          lg: "h-11 rounded-md px-8",
          icon: "h-10 w-10",
        },
      },
      defaultVariants: {
        variant: "default",
        size: "default",
      },
    },
  );

const customerVariants = getThemeVariants("customer");
const rekazVariants = getThemeVariants("rekaz");

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<ReturnType<typeof getThemeVariants>> {
  asChild?: boolean;
  theme?: "customer" | "rekaz";
  loading?: boolean;
}

const spinnerSizes = {
  default: "size-4",
  sm: "size-3",
  lg: "size-5",
  icon: "size-4",
};

const Spinner = ({ className }: { className?: string }) => (
  <Loader2 className={cn("animate-spin", className)} />
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      theme = "customer",
      asChild = false,
      loading = false,
      children,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    const buttonVariants =
      theme === "customer" ? customerVariants : rekazVariants;

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={loading || disabled}
        {...props}
      >
        {React.cloneElement(
          <div className="inline-flex items-center">
            {loading && (
              <Spinner
                className={cn("me-2", spinnerSizes[size || "default"])}
              />
            )}
            {children}
          </div>,
        )}
      </Comp>
    );
  },
);

Button.displayName = "Button";

export { Button, customerVariants, rekazVariants };
